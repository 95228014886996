import React from 'react';

const StoryContainer = ({ currentScene, fontSize, handleLinkClick }) => {
  const renderTextWithLinks = (text) => {
    const parts = text.split(/(go to \d+)/gi);
    return parts.map((part, index) => {
      const match = part.match(/go to (\d+)/i);
      if (match) {
        const targetSceneId = match[1];
        return (
          <a
            key={index}
            href="#"
            className="scene-link"
            onClick={(e) => handleLinkClick(e, targetSceneId)}
          >
            {part}
          </a>
        );
      }
      return part;
    });
  };

  return (
    <div className="text-panel">
      <div id="story-container" className="story-container">
        <div className="section-title">{currentScene && `Section ${currentScene.sceneId}`}</div>
        <div id="story-text" className="story-text" style={{ fontSize: `${fontSize}px` }}>
          {currentScene && currentScene.text.split('\n').map((line, index) => (
            <p key={index}>{renderTextWithLinks(line)}</p>
          ))}
        </div>
        <div id="story-options" className="story-options">
          {currentScene && currentScene.options && currentScene.options.map((option, index) => (
            <div key={index}>
              <a
                href="#"
                className="scene-link"
                onClick={(e) => handleLinkClick(e, option.sceneId)}
              >
                {option.text}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StoryContainer;
