import React from 'react';

const Navbar = ({ loadScene, goBack, handleSave, handleFontSizeChange }) => {
  return (
    <nav className="navbar">
      <div className="navbar-content">
        <img id="book-image" src={`${process.env.PUBLIC_URL}/assets/book1.jpg`} alt="Book" className="book-image" />
        <button className="play-button" onClick={loadScene}>Play / Resume</button>
        <button className="restart-button" onClick={loadScene}>Restart</button>
        <div className="button-panel">
          <button className="navigation-button" onClick={goBack}>Back</button>
          <button className="navigation-button" onClick={handleSave}>Save</button>
        </div>
        <div className="text-size-controls">
          <button className="text-size-button" onClick={() => handleFontSizeChange(1)}>A+</button>
          <button className="text-size-button" onClick={() => handleFontSizeChange(-1)}>A-</button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
