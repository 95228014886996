import React, { useState, useEffect } from 'react';
import Navbar from './SideBar';
import StoryContainer from './StoryContainer';
import ContentPanel from './ContentPanel';

const StoryPage = () => {
  const [storyData, setStoryData] = useState(null);
  const [currentScene, setCurrentScene] = useState(null);
  const [history, setHistory] = useState([]);
  const [fontSize, setFontSize] = useState(17);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const loadStoryData = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/storydata_modifiedchp1.json`);
        if (!response.ok) throw new Error('Failed to load story data.');
        const data = await response.json();
        setStoryData(data);
        const savedState = JSON.parse(localStorage.getItem('storyState'));
        if (savedState) {
          loadScene(savedState.chapter, savedState.sceneId, data, true);
        }
      } catch (error) {
        console.error('Error loading story data:', error);
      }
    };

    loadStoryData();
  }, []);

  const loadScene = (chapterName, sceneId, data, isBackNavigation = false) => {
    if (isProcessing) return;
    setIsProcessing(true);

    const chapter = data[chapterName];
    if (!chapter || !chapter[sceneId]) {
      console.error(`Scene with ID ${sceneId} in chapter ${chapterName} not found.`);
      setIsProcessing(false);
      return;
    }

    const scene = chapter[sceneId];
    if (!isBackNavigation) {
      setHistory(prevHistory => [...prevHistory, { chapter: chapterName, scene: sceneId }]);
    }
    setCurrentScene({ ...scene, sceneId, chapterName });
    setIsProcessing(false);
  };

  const handleLinkClick = (e, targetSceneId) => {
    e.preventDefault(); // Prevent the default anchor behavior
    if (storyData && currentScene) {
      loadScene(currentScene.chapterName, targetSceneId, storyData);
    }
  };

  const goBack = () => {
    if (history.length > 1) {
      const newHistory = [...history];
      newHistory.pop();
      const previousScene = newHistory[newHistory.length - 1];
      setHistory(newHistory);
      loadScene(previousScene.chapter, previousScene.scene, storyData, true);
    }
  };

  const handleSave = () => {
    const storyState = { chapter: history[history.length - 1].chapter, sceneId: history[history.length - 1].scene };
    localStorage.setItem('storyState', JSON.stringify(storyState));
  };

  const handleFontSizeChange = (increment) => {
    setFontSize(prevSize => Math.max(12, prevSize + increment));
  };

  return (
    <div className="container">
      <Navbar
        loadScene={() => loadScene('Chapter 1', '0', storyData)}
        goBack={goBack}
        handleSave={handleSave}
        handleFontSizeChange={handleFontSizeChange}
      />
      <div className="container">
        <StoryContainer
          currentScene={currentScene}
          fontSize={fontSize}
          handleLinkClick={handleLinkClick}
        />
        <ContentPanel currentScene={currentScene} />
      </div>
    </div>
  );
};

export default StoryPage;
