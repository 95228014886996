import React from 'react';

const ContentPanel = ({ currentScene }) => {
  return (
    <div className="content-panel">
      <div id="map-container" style={{ display: currentScene && currentScene.content?.map ? 'block' : 'none' }}>
        {currentScene && currentScene.content?.map && (
          <img id="map-image" src={currentScene.content.map} alt="Map" />
        )}
      </div>
      <div id="section-content" className="section-content">
        {currentScene && currentScene.text.split('\n').map((line, index) => (
          <p key={index}>{line}</p>
        ))}
      </div>
    </div>
  );
};

export default ContentPanel;
